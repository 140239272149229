/* Terminal styling */
.gradientCont {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  width: 100%;
  height: 100%;
}

.mainCont {
  width: 100%;
  margin: 0;
  overflow: auto;
}

.mainCont h2,
.mainCont h3 {
  text-align: center;
  opacity: 0;
  animation: fadeInAnimation 1s ease-in-out forwards;
}

.mainCont h2 {
  font-weight: 300;
  font-size: 48px;
  margin: 60px 0 0 0;
  color: white;
}

.mainCont h3 {
  font-weight: 400;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.75);
  margin: 0 0 60px 0;
  animation-delay: 0.5s;
}

.terminalCont {
  background: rgb(34, 34, 34);
  width: 100%;
  height: 100%;
  padding: 12px 0 0 0;
  margin-top: -20px;
  font-family: 'Roboto Mono', monospace;
  opacity: 0;
  animation: slideDownAnimation 0s ease-in-out 0s forwards, fadeInAnimation 0s ease-in-out 0s forwards;
}

.terminalContainer {
  height: 100%;
  width: 100%;
  /* Adjusted height to accommodate the input box */
}

.terminalContainer>div:first-child {
  /* display: none; */
}

/* .terminalCont .xterm-screen {
  width: 100% !important;
}

.terminalCont .xterm-screen .xterm-rows {
  width: 100% !important;
}

.terminalCont .xterm-screen .xterm-rows * {
  width: 100% !important;
} */


.xterm>.xterm-viewport {
  background-color: rgb(34, 34, 34) !important;
  overflow-y: hidden;
}

.userEnteredText {
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  padding: 0;
  display: inline-block;
}

/* Results container styling */
#terminalReslutsCont {
  width: 100%;
  padding: 12px;
  overflow-y: auto;
  resize: none;
  border: none;
  font-size: 14px;
  line-height: 28px;
  display: block;
  color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
}

#terminalReslutsCont a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

/* Add this style for the input command */
.inputCommand {
  color: #808080;
  /* Grey color */
}

/* Update the style for the input box */
#terminalTextInput {
  background: rgb(34, 34, 34);
  display: inline-block;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
  width: calc(100% - 36px);
  color: white;
  padding: 18px;
  font-size: 14px;
  outline: none;
  font-family: 'Roboto Mono', monospace;
}

/* Update the style for the input box container */
.terminalInput {
  display: flex;
  align-items: center;
  padding: 12px;
}

/* Update the style for the terminal prompt */
.terminalPrompt {
  color: #808080;
  /* Grey color */
  margin-right: 10px;
}

/* Keyframe animations */
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideDownAnimation {
  0% {
    margin-top: -20px;
  }

  100% {
    margin-top: 0;
  }
}

/* Responsive design */
@media screen and (max-width: 1000px) {
  .gradientCont {
    height: 220px;
  }

  .mainCont {
    width: 100%;
    padding: 0 12px;
  }

  .mainCont h2 {
    font-size: 32px;
    margin: 40px 0 0 0;
  }

  .mainCont h3 {
    font-size: 16px;
    margin: 0 0 40px 0;
  }

  #terminalReslutsCont {
    height: 100%;
  }
}